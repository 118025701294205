<template>
  <div lh="sm" pdt="2xs" pdr="lg" pdb="2xs" pdl="lg" talign="center" bg="5xs" fsize="sm" class="bg-blue-grey-darken-3">
    <NuxtLink :to="`/board/notice`" break="ellipsis-1" display="block" :title="$t('go-home')">
      <v-icon icon="mdi-music" fsize="sm" />
      <span fsize="xs" pd="2xs-x" color="xl">
        [ {{ $t('board-notice-category-notice') }} ]
      </span>
      <span>
        {{ $t('site-short-name') }}
      </span>
    </NuxtLink>
  </div>
</template>